<template>
  <div class="content">
    <MainHeader
      :description="$t('pages.materials.aditional_content.description')"
      :module="$t('pages.materials.aditional_content.module')"
      :page-title="$t('pages.materials.aditional_content.title')"
    />
    <wrapper class="content-page">
      <div class="container-filters">
        <form class="filter-area">
          <div class="select-area">
            <s-select
              v-model="selectedDocumentGrade"
              class="s-select-year"
              :disabled="false"
              :label="$t('pages.materials.aditional_content.grade')"
              :loading="null"
              :options="gradeOptions"
              :placeholder="$t('pages.materials.aditional_content.grade')"
              size="large"
              width="100%"
              @select="loadSubjetcsOptions()"
            />
            <s-select
              v-model="selectedDocumentSubject"
              class="s-select-subject"
              :disabled="false"
              :label="$t('pages.materials.aditional_content.subject')"
              :loading="null"
              :options="subjectOptions"
              :placeholder="$t('pages.materials.aditional_content.subject')"
              size="large"
            />
          </div>

          <s-input
            v-if="selectedDocumentGrade && selectedDocumentSubject"
            v-model="docSearch"
            autofocus
            class="aditional-content-input"
            :label="$t('pages.materials.aditional_content.search')"
            :placeholder="$t('pages.materials.aditional_content.search')"
            size="large"
            @focus="clearAll()"
          />

          <s-button
            icon="search"
            size="large"
            @click="selectDocument()"
          />
        </form>
      </div>

      <div class="doc-wrapper">
        <div class="doc-wrapper">
          <ColorDocsMiniature
            v-for="item in allDocuments"
            :key="item.id"
            card-color="#5B7A95"
            :card-date="item.publicationAt"
            :card-download="getFileFullUrl(item.fileLink)"
            :card-title="`${selectedDocumentGrade}-${selectedDocumentSubject}`"
            :card-type="item.title"
          />
        </div>
      </div>
    </wrapper>
    <EmptyState
      v-if="totalDocuments === 0 && !filtered"
      :image-src="require(`@/assets/img/examSearch.svg`)"
      img-empty-result-class
    >
      <h6 class="empty-desc">
        Use os filtros acima para acessar
        o documento da prova que você procura
      </h6>
    </EmptyState>
  </div>
</template>

<script>
import MainHeader from '@/components/shared/MainHeader.vue'
import ColorDocsMiniature from '@/components/shared/ColorDocsMiniature.vue'
import EmptyState from '@/components/shared/EmptyState.vue'

import aditionalContentService from '@/services/documentsService'

import { getFileFullUrl } from '@/utils/files'

export default {
  name: 'AditionalContent',
  components: {
    MainHeader,
    ColorDocsMiniature,
    EmptyState,
  },
  data() {
    return {
      allDocuments: [],
      gradeOptions: [],
      subjectOptions: [],
      selectedDocumentGrade: null,
      selectedDocumentSubject: null,
      docSearch: null,
      totalDocuments: 0,
      filtered: false,
    }
  },
  created() {
    this.loadGrades()
  },
  methods: {
    async loadGrades() {
      try {
        this.clearSelection()
        const { data } = await aditionalContentService.getAditionalContentDocs()
        this.totalDocuments = data.categories.length
        this.filtered = true

        data.categories.forEach((element) => {
          if (data.categories.includes(element)) {
            this.gradeOptions.push(element.value)
          }
        })
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    async loadSubjetcsOptions() {
      try {
        this.clearSelection()
        const { data } = await aditionalContentService.getAditionalContentDocs()
        this.totalDocuments = data.categories.length
        this.filtered = true

        data.categories.forEach((gradeItems) => {
          if (gradeItems.value === this.selectedDocumentGrade) {
            gradeItems.categories.forEach((element) => {
              if (gradeItems.categories.includes(element)) {
                this.subjectOptions.push(element.value)
              }
            })
          }
        })
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    selectDocument() {
      this.clearSearch()
      if (this.selectedDocumentGrade !== null
        && this.selectedDocumentSubject !== null) {
        this.getAllProgramContentDocuments()
      } else {
        this.$toasted.global.error({
          message: this.$t('pages.materials.aditional_content.emptyFilters'),
        })
      }
    },
    async getAllProgramContentDocuments() {
      try {
        const { data } = await aditionalContentService.getAditionalContentDocs()
        data.categories.forEach((gradeItems) => {
          if (gradeItems.value === this.selectedDocumentGrade) {
            gradeItems.categories.forEach((subjectItems) => {
              if (subjectItems.value === this.selectedDocumentSubject) {
                subjectItems.documents.forEach((file) => {
                  const fileTitle = file.title ? file.title : ''
                  const searchTerm = this.docSearch ? this.docSearch : ''
                  fileTitle.toLowerCase()
                  searchTerm.toLowerCase()

                  if (fileTitle.includes(searchTerm)) {
                    this.allDocuments.push(file)
                  }
                })
              }
            })
          }
        })
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    clearSearch() {
      this.allDocuments = []
    },
    clearSelection() {
      this.subjectOptions = []
      this.selectedDocumentSubject = null
    },
    getFileFullUrl,
  },
}
</script>

<style lang="sass">
.sas-wrapper.content-page
  padding-top: 40px
  background: transparent
  padding-bottom: 20px

.content-page
  .sas-box
    border-radius: 0px !important

    +mq-l--mf
      border-radius: $border-radius-m !important

.doc-wrapper
  display: grid
  grid-gap: 32px
  grid-template-columns: repeat(1, 1fr)

  +mq-m--mf
    grid-template-columns: repeat(2, 1fr)

  +mq-l--mf
    grid-template-columns: repeat(4, 1fr)

.main-subtitle
  display: inline-block
  margin-bottom: 36px
  font-size: 22px
  line-height: 28px
  color: #666E75

.simple-separator
  border: 0
  border-bottom: 1px solid #C4C4C4
  margin: 23px 0

.books-filter
  margin: 24px 0 32px 0
  display: inline

  .btn-content
    display: inline
    margin-right: 16px

.btn-book.sas-button
  margin-bottom: 14px
  box-shadow: none
  border: 1px solid #53595F

  &:not(:last-of-type)
    margin-right: 16px

  &:focus
    box-shadow: none !important

  &.active-book
    background: #666E75
    border: 1px solid #666E75 !important
    color: $color-white !important

.container-filters
  width: 100%
  display: flex
  align-items: flex-start
  flex-flow: wrap
  padding-bottom: 24px
  padding-top: 20px

  +mq-m--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

  +mq-l--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

.select-area
  display: flex
</style>

<style lang="scss" scoped>
.aditional-content-input {
  margin-right: 10px;
  height: 75px;
}
</style>
